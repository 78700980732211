export function toISOLocal(d: Date) {
  const z = (n: number) => ("0" + n).slice(-2);
  const zz = (n: number) => ("00" + n).slice(-3);
  let off = d.getTimezoneOffset();
  const sign = off > 0 ? "-" : "+";
  off = Math.abs(off);

  return (
    d.getFullYear() +
    "-" +
    z(d.getMonth() + 1) +
    "-" +
    z(d.getDate()) +
    "T" +
    z(d.getHours()) +
    ":" +
    z(d.getMinutes()) +
    ":" +
    z(d.getSeconds()) +
    "." +
    zz(d.getMilliseconds()) +
    sign +
    z((off / 60) | 0) +
    ":" +
    z(off % 60)
  );
}

export function fixTimeString(time: string) {
  const timeParts = time.split(":");
  const hour = parseInt(timeParts[0]);
  const minute = timeParts[1];
  return `${hour > 12 ? hour - 12 : hour}:${minute}`;
}
