import {
  DatetimeChangeEventDetail,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonModal,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonTitle,
  IonToolbar,
  ItemReorderEventDetail,
  useIonAlert
} from "@ionic/react";
import { addSharp } from "ionicons/icons";
import { Fragment, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { ActivityCard } from "../components/ActivityCard";
import { TimeCard } from "../components/TimeCard";
import { activityImages } from "../library/activityImages";
import { activityState } from "../library/state/activity.state";
import { supabase } from "../library/supabaseClient";
import { fixTimeString, toISOLocal } from "../library/time";

export function Scheduling() {
  const [presentAlert] = useIonAlert();
  const activities = useRecoilValue(activityState);
  const [showModal, setShowModal] = useState(false);
  const dateModalRef = useRef<HTMLIonModalElement>(null);
  const activityModalRef = useRef<HTMLIonModalElement>(null);
  const rightNow = new Date();
  rightNow.setHours(new Date().getHours(), 0, 0, 0);
  const allActivities = Object.entries(activityImages);
  const [filteredActivities, setFilteredActivities] =
    useState<typeof allActivities>(allActivities);
  const [activityFilter, setActivityFilter] = useState<string>("");
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [reorderEnabled, setReorderEnabled] = useState(false);

  useEffect(() => {
    const loweredFilter = activityFilter.toLowerCase();
    const filtered = allActivities.filter(
      ([key, value]) =>
        key.toLowerCase().includes(loweredFilter) ||
        value.toLowerCase().includes(loweredFilter)
    );
    setFilteredActivities(filtered);
  }, [activityFilter]);

  function openDateModal() {
    return dateModalRef.current?.present();
  }

  function openActivityModal(time: string) {
    setSelectedTime(time);
    return activityModalRef.current?.present();
  }

  async function saveTimeSlot({
    detail,
  }: {
    detail: DatetimeChangeEventDetail;
  }) {
    if (!detail.value) {
      return;
    }

    const time = (detail.value as string).split("T")[1];
    const newEntry = { time };

    const { error } = await supabase.from("activity_times").insert(newEntry);

    if (error) {
      presentAlert({
        header: "Error",
        message: error.message.includes("duplicate key value")
          ? "Time slot already exists."
          : error.message,
        buttons: ["OK"],
      });
    }
  }

  async function removeTimeSlot(id: string, time: string) {
    await presentAlert({
      header: "Confirm",
      message: `Are you sure you want to delete this time slot (${fixTimeString(
        time
      )})?`,
      buttons: [
        "Cancel",
        {
          text: "Delete",
          handler: async () => {
            const { error } = await supabase
              .from("activity_times")
              .delete()
              .match({ id });

            if (error) {
              presentAlert({
                header: "Error",
                message: error.message,
                buttons: ["OK"],
              });
            }
          },
        },
      ],
    });
  }

  async function removeActivity(id: string, activity: string) {
    await presentAlert({
      header: "Confirm",
      message: `Are you sure you want to delete this activity (${activityImages[activity]})?`,
      buttons: [
        "Cancel",
        {
          text: "Delete",
          handler: async () => {
            const { error } = await supabase
              .from("activities")
              .delete()
              .match({ id });

            if (error) {
              presentAlert({
                header: "Error",
                message: error.message,
                buttons: ["OK"],
              });
            }
          },
        },
      ],
    });
  }

  async function addActivityToTimeSlot(activity: string) {
    const relevantTime = activities.find((a) => a.time === selectedTime);
    if (!relevantTime) {
      return;
    }

    const newEntry = {
      time_id: relevantTime.id,
      name: activity,
      sort_order: relevantTime.activities.length,
    };
    const { error } = await supabase.from("activities").insert(newEntry);

    if (error) {
      presentAlert({
        header: "Error",
        message: error.message,
        buttons: ["OK"],
      });
    }
  }

  async function handleReorder(
    event: CustomEvent<ItemReorderEventDetail>,
    activityTime: (typeof activities)[0]
  ) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    const selectedActivities: any[] = activityTime.activities.map((x) => ({
      ...x,
    }));
    const { from, to } = event.detail;

    selectedActivities.forEach((x: any, index) => {
      // Change the sort order of all the items to reflect the new order
      if (x.sort_order === null) {
        x.sort_order = selectedActivities.length;
      } else if (from < to && index > from && index <= to) {
        x.sort_order = index - 1;
      } else if (from > to && index >= to && index < from) {
        x.sort_order = index + 1;
      }

      x.time_id = activityTime.id;
    });

    selectedActivities[from].sort_order = to;
    console.log("Upserting", selectedActivities);
    const { error } = await supabase
      .from("activities")
      .upsert(selectedActivities);

    if (error) {
      console.error(error);
    }

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete();
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              <IonButton size="small" onClick={openDateModal}>
                Add Time Slot
              </IonButton>
              <div className="inline-block align-sub pl-10">
                <IonCheckbox
                  labelPlacement="end"
                  value={reorderEnabled}
                  onIonChange={(e) => setReorderEnabled(e.detail.checked)}
                >
                  Reorder
                </IonCheckbox>
              </div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <div className="px-5">
            {activities.length === 0 && (
              <div className="text-fully-center text-2xl font-aloja">
                No activities scheduled yet.
              </div>
            )}
            {activities.map((a) => (
              <Fragment key={a.time}>
                <TimeCard
                  time={a.time}
                  onClick={() => removeTimeSlot(a.id, a.time)}
                />
                <div className="py-5">
                  <IonReorderGroup
                    disabled={!reorderEnabled}
                    onIonItemReorder={(e) => handleReorder(e, a)}
                  >
                    {a.activities.map((b) => (
                      <IonReorder
                        key={b.id}
                        className="w-[80%] bg-blue-50 mb-2"
                      >
                        <ActivityCardContainer
                          onClick={() => removeActivity(b.id, b.name)}
                        >
                          <ActivityCard selected={b.name} />
                        </ActivityCardContainer>
                      </IonReorder>
                    ))}
                  </IonReorderGroup>
                  {!reorderEnabled && (
                    <ActivityCardContainer
                      onClick={() => openActivityModal(a.time)}
                    >
                      <AddCardPlaceholder>
                        <div className="h-full text-fully-center text-3xl">
                          <IonIcon icon={addSharp} />
                        </div>
                      </AddCardPlaceholder>
                    </ActivityCardContainer>
                  )}
                </div>
              </Fragment>
            ))}
          </div>

          <IonModal
            ref={activityModalRef}
            trigger="open-modal"
            onWillDismiss={() => setActivityFilter("")}
          >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton
                    onClick={() => activityModalRef.current?.dismiss()}
                  >
                    Cancel
                  </IonButton>
                </IonButtons>
                <IonTitle>
                  Choose an activity for {fixTimeString(selectedTime)}
                </IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <div className="mb-5">
                <IonInput
                  type="text"
                  placeholder="Search"
                  value={activityFilter}
                  onIonInput={(e) =>
                    setActivityFilter(e.detail.value as string)
                  }
                  clearInput={true}
                />
              </div>
              {filteredActivities.map(([key]) => (
                <ActivityCardContainer
                  key={key}
                  onClick={() => {
                    activityModalRef.current?.dismiss();
                    return addActivityToTimeSlot(key);
                  }}
                >
                  <ActivityCard selected={key} />
                </ActivityCardContainer>
              ))}
            </IonContent>
          </IonModal>

          <DateModal
            keepContentsMounted={true}
            // initialBreakpoint={0.12}
            // handle={false}
            ref={dateModalRef}
          >
            <IonContent>
              <IonToolbar>
                <IonTitle>Add New Time Slot</IonTitle>
              </IonToolbar>
              <IonDatetime
                value={toISOLocal(rightNow)}
                presentation="time"
                size="cover"
                showDefaultButtons={true}
                onIonChange={saveTimeSlot}
              ></IonDatetime>
            </IonContent>
          </DateModal>
        </IonContent>
      </IonPage>
    </>
  );
}

const AddCardPlaceholder = styled.div`
  display: inline-block;
  background-color: #f5f5f5;
  height: 100%;
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border: 1px solid black;
`;

const ActivityCardContainer = styled.div`
  display: inline-block;
  width: calc(25% - 15px);
  margin-right: 15px;
`;

const DateModal = styled(IonModal)`
  --max-height: 293px;
  --min-height: 293px;
  --height: 293px;
`;
