import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { Fragment, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { ActivityCard } from "../components/ActivityCard";
import { TimeCard } from "../components/TimeCard";
import { activityState } from "../library/state/activity.state";

export function CurrentActivitiesTab() {
  const activities = useRecoilValue(activityState);
  const [todaysActivities, setTodaysActivities] = useState<typeof activities>(
    []
  );

  function updateActivities() {
    const rightNow =
      new Date().toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
      }) + ":00:00";

    const rightNowTimePeriod = new Date();
    rightNowTimePeriod.setHours(new Date().getHours() + 3);
    const hourFromNowString =
      rightNowTimePeriod.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
      }) + ":00:00";

    const todaysStuff = activities
      .filter((x) => !!x.activities.length)
      .filter((x) => x.time >= rightNow)
      .filter((x) => x.time <= hourFromNowString);
    setTodaysActivities(todaysStuff);
  }

  useEffect(() => {
    updateActivities();
  }, [activities]);

  useEffect(() => {
    // Update current activities at the top of every hour

    // Get difference in ms from now to the end of the house
    const now = new Date();
    const endOfHour = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours() + 1,
      0,
      0
    );
    const diff = endOfHour.getTime() - now.getTime();

    // Create a recursive timeout to update the activities every hour, starting
    // at the end of the current hour
    let timeout = setTimeout(() => update(), diff);
    const update = function () {
      updateActivities();
      timeout = setTimeout(() => {
        update();
      }, 1000 * 60 * 60);
    };

    // Clear the timeout when the component unmounts
    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense"></IonHeader>
        <div className="px-5">
          {todaysActivities.length === 0 && (
            <div className="text-fully-center text-3xl font-aloja pt-10">
              No more activities right now!
              <br />
              Check the today tab for more.
            </div>
          )}
          {todaysActivities.map((a) => (
            <Fragment key={a.time}>
              <TimeCard time={a.time} />
              <div className="py-5">
                {a.activities.map((b) => (
                  <ActivityCardContainer key={b.id}>
                    <ActivityCard selected={b.name} />
                  </ActivityCardContainer>
                ))}
              </div>
            </Fragment>
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
}

const ActivityCardContainer = styled.div`
  display: inline-block;
  width: calc(25% - 15px);
  margin-right: 15px;
`;
