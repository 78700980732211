import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { Fragment, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { ActivityCard } from "../components/ActivityCard";
import { TimeCard } from "../components/TimeCard";
import { activityState } from "../library/state/activity.state";

export function TodaysActivities() {
  const activities = useRecoilValue(activityState);
  const [filteredActivities, setFilteredActivities] = useState<
    typeof activities
  >([]);

  useEffect(() => {
    setFilteredActivities(activities.filter((x) => !!x.activities.length));
  }, [activities]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense"></IonHeader>
        <div className="px-5">
          {filteredActivities.length === 0 && (
            <div className="text-fully-center text-2xl font-aloja">
              No activities scheduled yet today!
            </div>
          )}
          {filteredActivities.map((a) => (
            <Fragment key={a.time}>
              <TimeCard time={a.time} />
              <div className="py-5">
                {a.activities.map((b) => (
                  <ActivityCardContainer key={b.id}>
                    <ActivityCard selected={b.name} />
                  </ActivityCardContainer>
                ))}
              </div>
            </Fragment>
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
}

const ActivityCardContainer = styled.div`
  display: inline-block;
  width: calc(25% - 15px);
  margin-right: 15px;
`;
