import { useEffect, useState } from "react";
import styled from "styled-components";
import { activityImages } from "../library/activityImages";

interface ActivityCardProps {
  selected?: string;
}

export function ActivityCard({ selected }: ActivityCardProps) {
  // Default to first key in the object
  const [selectedImage, setSelectedImage] = useState<string>(
    Object.values(activityImages)[0]
  );

  useEffect(() => {
    if (!selected) {
      return;
    }

    setSelectedImage(selected);
  }, [selected]);

  return (
    <CardBackground
      backgroundImage={`/assets/images/activities/${selectedImage}`}
    />
  );
}

const CardBackground = styled.div<{ backgroundImage: string }>`
  position: relative;
  display: inline-block;
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: 100% 100%;
    background-image: url(${(props) => props.backgroundImage});
  }

  &.no-box::before {
    top: -3%;
    left: -3%;
    right: -1px;
    bottom: -1px;
    background-size: 101.5% 101.5%; /* Adjust this value to match your image width and height plus 2px (width + 2) x (height + 2) */
    background-position: 1px 1px;
  }
`;
