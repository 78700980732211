import { SupabaseClient } from '@supabase/supabase-js';
import { Database, Table } from './database';

const DEFAULT_LIMIT = 10;

export const getPagination = (page: number, size: number) => {
	const limit = size ? +size : DEFAULT_LIMIT;
	const from = page ? (page - 1) * limit : 0;
	const to = page ? from + size - 1 : size - 1;

	return { from, to };
};

export function realtimeChanges<
	TableName extends keyof Database['public']['Tables'],
	ReturnTable = Table<TableName>
>(
	supabase: SupabaseClient<Database>,
	{ table, filter }: { table: TableName; filter?: string },
	{
		onInsert: insertHandler,
		onUpdate: updateHandler,
		onDelete: deleteHandler,
	}: {
		onInsert?: (payload: ReturnTable) => void;
		onUpdate?: (payload: ReturnTable) => void;
		onDelete?: (payload: ReturnTable) => void;
	}
) {
	const hasInsert = !!insertHandler;
	const hasUpdate = !!updateHandler;
	const hasDelete = !!deleteHandler;

	const event = atLeastTwoTrue(hasInsert, hasUpdate, hasDelete)
		? '*'
		: hasInsert
			? 'INSERT'
			: hasUpdate
				? 'UPDATE'
				: hasDelete
					? 'DELETE'
					: '*';
	const channel = supabase
		.channel(`${table}_changes`)
		.on(
			'postgres_changes',
			{
				event: event as any, // TS types are dumb, but this is actually totally valid without "as any"
				schema: 'public',
				table,
				filter,
			},
			payload => {
				payload.eventType;
				switch (payload.eventType) {
					case 'INSERT':
						insertHandler?.(payload.new as ReturnTable);
						return;
					case 'UPDATE':
						updateHandler?.(payload.new as ReturnTable);
						return;
					case 'DELETE':
						deleteHandler?.(payload.old as ReturnTable);
						return;
				}
			}
		)
		.subscribe();

	return () => {
		console.log(`${table} : ${filter} - watcher unsubscribed`);
		channel.unsubscribe();
	};
}

function atLeastTwoTrue(a: boolean, b: boolean, c: boolean) {
	return a !== b ? c : a;
}

