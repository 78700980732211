import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { pencilOutline, stopwatchOutline, todayOutline } from "ionicons/icons";
import { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { realtimeChanges } from "./library/realtime-changes";
import { activityState } from "./library/state/activity.state";
import { supabase } from "./library/supabaseClient";
import { CurrentActivitiesTab } from "./pages/CurrentActivitiesTab";
import { Scheduling } from "./pages/Scheduling";
import { TodaysActivities } from "./pages/TodaysActivities";
import "./styles.scss";

/* Theme variables */
import "./theme/variables.css";

setupIonicReact();

export default function App() {
  const setActivities = useSetRecoilState(activityState);

  function loadActivityData() {
    supabase
      .from("activity_times")
      .select("id, time, activities(id, name, sort_order)")
      .order("time", { ascending: true })
      .then(({ data, error }) => {
        if (error) {
          console.error("Error loading activities", error);
          return;
        }

        setActivities(
          data.map((d) => {
            return {
              id: d.id,
              time: d.time,
              activities: Array.isArray(d.activities)
                ? d.activities.sort((a, b) => {
                    return (a.sort_order || 0) > (b.sort_order || 0) ? 1 : -1;
                  })
                : [],
            };
          })
        );
      });
  }

  useEffect(() => {
    loadActivityData();

    const activityTimesSubscription = realtimeChanges(
      supabase,
      { table: "activity_times" },
      {
        onUpdate: loadActivityData,
        onDelete: loadActivityData,
        onInsert: loadActivityData,
      }
    );

    const activitiesSubscription = realtimeChanges(
      supabase,
      { table: "activities" },
      {
        onUpdate: loadActivityData,
        onDelete: loadActivityData,
        onInsert: loadActivityData,
      }
    );

    return () => {
      activityTimesSubscription();
      activitiesSubscription();
    };
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/current">
              <CurrentActivitiesTab />
            </Route>
            <Route exact path="/today">
              <TodaysActivities />
            </Route>
            <Route path="/scheduling">
              <Scheduling />
            </Route>
            <Route exact path="/">
              <Redirect to="/current" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="tab1" href="/current">
              <IonIcon aria-hidden="true" icon={stopwatchOutline} />
              <IonLabel>Right Now</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab2" href="/today">
              <IonIcon aria-hidden="true" icon={todayOutline} />
              <IonLabel>Today</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab3" href="/scheduling">
              <IonIcon aria-hidden="true" icon={pencilOutline} />
              <IonLabel>Edit Schedule</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
}
