export const activityImages: Record<string, string> = {
  "activity.png": "activity",
  "animalkingdom.png": "animal kingdom",
  "apointment.png": "appointment",
  "art.png": "art",
  "artsandcrafts.png": "arts and crafts",
  "baking.png": "baking",
  "balloons.png": "balloons",
  "ballpit.png": "ball pit",
  "bath.png": "bath",
  "beach.png": "beach",
  "bedtime.png": "bedtime",
  "bedtime2.png": "bedtime 2",
  "bedtimestory.png": "bedtime story",
  "bike.png": "bike",
  "blocks.png": "blocks",
  "bottle.png": "bottle",
  "breakfast.png": "breakfast",
  "brushhair.png": "brush hair",
  "brushteeth.png": "brush teeth",
  "cleaning.png": "cleaning",
  "cleanup.png": "clean up",
  "cleanuptoys.png": "clean up toys",
  "coffeeshop.png": "coffee shop",
  "cold.png": "cold",
  "coloring.png": "coloring",
  "combhair.png": "comb hair",
  "comfortable.png": "comfortable",
  "cooking.png": "cooking",
  "crafts.png": "crafts",
  "daddyandme.png": "daddy and me",
  "dancing.png": "dancing",
  "dentist.png": "dentist",
  "dinner.png": "dinner",
  "doctor.png": "doctor",
  "dressed.png": "dressed",
  "dressup.png": "dress up",
  "eatingout.png": "eating out",
  "epcot.png": "Epcot",
  "exercise.png": "exercise",
  "facetime.png": "FaceTime",
  "familyouting.png": "family outing",
  "feeddog.png": "feed dog",
  "fingerpaint.png": "finger paint",
  "games.png": "games",
  "grocerystore.png": "grocery store",
  "gymnastics.png": "gymnastics",
  "haircut.png": "haircut",
  "holywoodstudios.png": "Hollywood studios",
  "hot.png": "hot",
  "imaginationbox.png": "imagination box",
  "indoorcleaning.png": "indoor cleaning",
  "indoorplay.png": "indoor play",
  "kineticsand.png": "kinetic sand",
  "kiwicobox.png": "kiwi co box",
  "laundry.png": "laundry",
  "lawn.png": "lawn",
  "learning.png": "learning",
  "legos.png": "Legos",
  "library.png": "library",
  "lotion.png": "lotion",
  "lunch.png": "lunch",
  "magickingdom.png": "magic kingdom",
  "mail.png": "mail",
  "makebed.png": "make bed",
  "mamaandme.png": "mama and me",
  "math.png": "math",
  "milk.png": "milk",
  "movie.png": "movie",
  "music.png": "music",
  "nap.png": "nap",
  "outdoorplay.png": "outdoor play",
  "park.png": "park",
  "partlycloudy.png": "partly cloudy",
  "party.png": "party",
  "photos.png": "photos",
  "pjs.png": "pjs",
  "playdate.png": "play date",
  "playdough.png": "play dough",
  "playground.png": "playground",
  "playroom.png": "playroom",
  "pretend.png": "pretend",
  "putawayclothes.png": "put away clothes",
  "putawaydishes.png": "put away dishes",
  "putawaygroceries.png": "put away groceries",
  "puzzle.png": "puzzle",
  "quiettime.png": "quiet time",
  "rain.png": "rain",
  "reading.png": "reading",
  "school.png": "school",
  "science.png": "science",
  "screentime.png": "screen time",
  "selfplay.png": "self play",
  "sensory.png": "sensory",
  "shopping.png": "shopping",
  "signlanguage.png": "sign language",
  "singing.png": "singing",
  "snack.png": "snack",
  "snow.png": "snow",
  "songtime.png": "song time",
  "splashpad.png": "splash pad",
  "sports.png": "sports",
  "statepark.png": "state park",
  "stem.png": "STEM",
  "stickers.png": "stickers",
  "storytime.png": "story time",
  "strollerwalk.png": "stroller walk",
  "sunny.png": "sunny",
  "surprise.png": "surprise",
  "sweep.png": "sweep",
  "swimming.png": "swimming",
  "trimnails.png": "trim nails",
  "trypotty.png": "try potty",
  "verticalplay.png": "vertical play",
  "vet.png": "vet",
  "vitamins.png": "vitamins",
  "wakeup.png": "wake up",
  "walk.png": "walk",
  "washcar.png": "wash car",
  "washhands.png": "wash hands",
  "watertable.png": "water table",
  "windy.png": "windy",
  "writing.png": "writing",
  "yoga.png": "yoga",
  "youtube.png": "YouTube",
};
