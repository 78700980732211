import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fixTimeString } from '../library/time';

interface TimeCardProps {
	time: string;
	onClick?: () => void;
}

export function TimeCard({ time, onClick }: TimeCardProps) {
	const [currentTime, setCurrentTime] = useState(time);

	useEffect(() => {
		// Convert time string from hh:mm to h:mm
		setCurrentTime(fixTimeString(time));
	}, [time]);

	return (
		<Card className="aspect-video" onClick={onClick}>
			<div className="font-aloja text-fully-center inline-block h-full w-full pt-1.5">{currentTime}</div>
		</Card>
	);
}

const Card = styled.div`
  display: inline-block;
  width: 150px;
  background-image: url('/assets/images/timebg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: ${150 * .2}px;
  border: 1px solid rgba(191, 139, 102, 0.75);
`;
